<template>
  <b-overlay
    :show="loading"
  >
    <b-btn
      class="mt-4"
      variant="main"
      size="lg"
      block
      @click="save"
    >
      Continuar
      <i class="bi bi-chevron-left"></i>
    </b-btn>
  </b-overlay>
</template>

<script>

export default {
  name: "BtnCotacao",
  data() {
    return {
      show: false
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading.page;
    }
  },
  methods: {
    async save() {
      try {

        let erros = [];

        if(this.$store.state.cotacao.marca_id == null) {
          erros.push({'marca_id': ['O campo Marca é obrigatório.']});
        }
        if(this.$store.state.cotacao.modelo_id == null) {
          erros.push({'modelo_id': ['O campo Modelo é obrigatório.']});
        }
        if(this.$store.state.cotacao.ano_id == null) {
          erros.push({'ano_id': ['O campo Ano é obrigatório.']});
        }
        if(this.$store.state.cotacao.estado_id == null) {
          erros.push({'estado_id': ['O campo Estado é obrigatório.']});
        }

        if(this.$store.state.simulacao.implemento) {
          if(this.$store.state.cotacao.valor_implemento == null || this.$store.state.cotacao.valor_implemento == 'R$ 0,00') {
            erros.push({'valor_implemento': ['O campo Valor é obrigatório.']});
          }
        }

        if(erros.length > 0) {
          this.$store.dispatch('setErrors', erros.reduce((a, b) => Object.assign(a, b), {}));
        } else {
          await this.$router.push({
              name: 'cliente',
            }
          );
        }
        
      } catch (error) {
        console.log(error.response);
      }
    }
  },
}
</script>
