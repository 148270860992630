<template>
  <div class="form-group">
    <div class="v-input" :class="{'v-input-disabled': marcas.length === 0}">
      <fieldset class="fieldset" :class="{'error-field': invalid}">
          <legend for="marca"><span>Marca</span></legend>
          <div class="v-select">  
            <v-select
              v-model="marca"
              id="marca"
              :loading="loading"
              label="name"
              :clearable="true"
              :disabled="marcas.length === 0"
              placeholder="Selecione uma marca"
              :reduce="opt => opt.id"
              outlined
              :options="marcas">
              <div slot="no-options">Marca não encontrada</div>
            </v-select>
          </div>
      </fieldset>
    </div>
    <b-form-invalid-feedback :force-show="invalid">
      <div v-for="(erro, index) in erros" :key="`error-ano-${index}`">
        {{ erro }}
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: "Marca",
  computed: {
    loading() {
      return this.$store.state.loading.marcas;
    },
    erros() {
      return this.$store.state.erros.marca_id;
    },
    invalid() {
      return this.erros.length > 0;
    },
    marcas() {
      return this.$store.state.marcas;
    },
    marca: {
      get() {
        return this.$store.state.cotacao.marca_id;
      },
      set(value) {
        return this.$store.dispatch('setMarca', value);
      }
    }
  },
  mounted(){
    if(localStorage.getItem("marca")){
      this.$store.dispatch('setMarca', Number(localStorage.getItem("marca")));
    }
  },
  watch:{
    marca(newMarca){
      if(newMarca) {
        localStorage.setItem("marca", newMarca);
      } else {
        localStorage.removeItem("marca");
      }
    }
  }
};
</script>