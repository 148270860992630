<template>
  <div class="row">
    <div class="col-md-12">
      <form>
        <div class="form-group">
          <b-form-checkbox
            switch
            v-model="pesado"
          >
            <span>Pickup Pesada?</span>
          </b-form-checkbox>
        </div>
        <Marca/>
        <Modelo/>
        <div class="form-row">
          <Ano/>
          <Estado/>
        </div>
        <BtnCotacao/>
      </form>
    </div>
  </div>
</template>

<script>
import Marca from '@/components/Marca.vue';
import Modelo from '@/components/Modelo.vue';
import Ano from '@/components/Ano.vue';
import Estado from '@/components/Estado.vue';
import BtnCotacao from '@/components/BtnCotacao.vue';

export default {
  name: 'Carro',
  components: {
    Marca,
    Modelo,
    Ano,
    Estado,
    BtnCotacao
  },
  data() {
    return {}
  },
  computed: {
    pesado: {
      get() {
        return this.$store.state.cotacao.pesado || localStorage.getItem("pesado");
      },
      set(value) {
        this.$store.commit('SET_COTACAO_PESADO', value);
      }
    }
  },
  mounted(){
    if(localStorage.getItem("pesado")){
      this.$store.commit('SET_COTACAO_PESADO', JSON.parse(localStorage.getItem("pesado")));
    } else {
      this.$store.commit('SET_COTACAO_PESADO', false);
    }
  },
  watch:{
    pesado(newPesado){
      localStorage.setItem("pesado", newPesado);
    }
  }
}
</script>
