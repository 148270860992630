<template>
  <main>
    <div class="container-md">
      <div class="row">
        <div class="col-md-3 col-md-offset-3"></div>
        <div class="col-md-6">
          <b-modal v-model="blacklist" ok-variant="main" ok-only title="Mensagem">
            <div class="row">
              <div class="col-md-12 text-center">
                <b-icon icon="exclamation-circle" class="icon-message"></b-icon>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2 col-md-offset-2"></div>
              <div class="col-md-8 text-center">
                <p v-html="error_blacklist[1]"></p>
              </div>
              <div class="col-md-2 col-md-offset-2"></div>
            </div>
          </b-modal>
          <form>
            <h4 class="mt-4 mb-4">Informe os dados para cotação</h4>
            <div class="form-group">
              <div class="v-input input-field">
                <fieldset class="fieldset" :class="{'error-field': nomeState}">
                    <legend for="nome"><span>Nome</span></legend>
                    <b-form-input
                      id="nome"
                      v-model="nome"
                      placeholder="Informe seu nome"
                      v-on:keyup="validInput('nome')"
                    />
                </fieldset>
              </div>
              <b-form-invalid-feedback id="nome" :force-show="nomeState">
                <div v-for="(erro, index) in nomeErros" :key="`error-nome-${index}`">
                  {{ erro }}
                </div>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group">
              <div class="v-input input-field">
                <fieldset class="fieldset" :class="{'error-field': emailState}">
                    <legend for="email"><span>Email</span></legend>
                    <b-form-input
                      id="email"
                      v-model="email"
                      placeholder="Informe seu email"
                      v-on:keyup="validInput('email')"
                    />
                </fieldset>
              </div>        
              <b-form-invalid-feedback id="email" :force-show="emailState">
                <div v-for="(erro, index) in emailErros" :key="`error-email-${index}`">
                  {{ erro }}
                </div>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group">
              <div class="v-input input-field">
                <fieldset class="fieldset" :class="{'error-field': telefoneState }">
                    <legend for="telefone"><span>Telefone</span></legend>
                    <b-form-input
                      id="telefone"
                      v-model="telefone"
                      placeholder="(__) _____-____"
                      v-mask="'(##) #####-####'"
                      v-on:keyup="validInput('telefone')"
                    />
                </fieldset>
              </div> 
              <b-form-invalid-feedback id="telefone" :force-show="telefoneState">
                <div v-for="(erro, index) in telefoneErros" :key="`error-telefone-${index}`">
                  {{ erro }}
                </div>
              </b-form-invalid-feedback>
            </div>
            <b-overlay
              :show="loading"
            >
              <b-btn
                class="mt-4"
                variant="main"
                size="lg"
                block
                @click="save"
              >
                VER VALOR DA PROTEÇÃO
              </b-btn>
            </b-overlay>
          </form>
        </div>
        <div class="col-md-3 col-md-offset-3"></div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'Cliente',
  data() {
    return {
      nomeValidation: true,
      emailValidation: true,
      telefoneValidation: true
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading.page;
    },
    error_blacklist: {
      get() {
        return this.$store.state.erros.error_blacklist;
      }
    },
    erros: {
      get() {
        return this.$store.state.erros;
      }
    },
    blacklist: {
      get() {
        return this.$store.state.cotacao.blacklist;
      },
      set(value) {
        this.$store.commit('SET_BLACKLIST', value);
      }
    },
    nome: {
      get() {
        return this.$store.state.cotacao.nome || localStorage.getItem("nome");
      },
      set(value) {
        return this.$store.commit('SET_COTACAO_NOME', value);
      }
    },
    email: {
      get() {
        return this.$store.state.cotacao.email || localStorage.getItem("email");
      },
      set(value) {
        return this.$store.commit('SET_COTACAO_EMAIL', value);
      }
    },
    telefone: {
      get() {
        return this.$store.state.cotacao.telefone || localStorage.getItem("telefone");
      },
      set(value) {
        return this.$store.commit('SET_COTACAO_TELEFONE', value);
      }
    },
    nomeErros() {
      return this.$store.state.erros.nome;
    },
    emailErros() {
      return this.$store.state.erros.email;
    },
    telefoneErros() {
      return this.$store.state.erros.telefone;
    },
    nomeState() {
      return this.nomeErros.length > 0 ? true : null;
    },
    emailState() {
      return this.emailErros.length > 0 ? true : null;
    },
    telefoneState() {
      return this.telefoneErros.length > 0 ? true : null;
    }
  },
  methods: {
    async save () {
      try {
        await this.$store.dispatch('saveCotacao');
        await this.$router.push({name: 'cotacao'});
      } catch (error) {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Erro ao processar requisição',
          text: 'Ocorreu um erro ao processar sua requisição, tente novamente mais tarde!!'
        });
      }
    },
    validInput(name) {

      if(name == 'nome') {
        if(this.$store.state.cotacao.nome != '') {
          this.$store.state.erros.nome = {};
        }
      }
      
      if(name == 'email') {
        if(this.$store.state.cotacao.email != '') {
          this.$store.state.erros.email = {};
        }
      }

      if(name == 'telefone') {
        if(this.$store.state.cotacao.telefone != '') {
          this.$store.state.erros.telefone = {};
        }
      }

    }
  },
  mounted(){
    if(localStorage.getItem("nome")){
      this.$store.dispatch('setNome', localStorage.getItem("nome"));
    }
    if(localStorage.getItem("email")){
      this.$store.dispatch('setEmail', localStorage.getItem("email"));
    } 
    if(localStorage.getItem("telefone")){
      this.$store.dispatch('setTelefone', localStorage.getItem("telefone"));
    }
    if(localStorage.getItem("tipo")){
      this.$store.dispatch('setTipo', {'flag': false, 'label': Number(localStorage.getItem("tipo")) } );
    }
    if(localStorage.getItem("marca")){
      this.$store.dispatch('setMarca', Number(localStorage.getItem("marca")));
    }
    if(localStorage.getItem("modelo")){
      this.$store.dispatch('setModelo', Number(localStorage.getItem("modelo")));
    }
    if(localStorage.getItem("ano")){
      this.$store.dispatch('setAno', localStorage.getItem("ano"));
    }
    if(localStorage.getItem("estado")){
      this.$store.dispatch('setEstado', Number(localStorage.getItem("estado")));
    }
  },
  watch:{
    nome(newNome){
      localStorage.setItem("nome", newNome);
    },
    email(newEmail){
      localStorage.setItem("email", newEmail);
    },
    telefone(newTelefone){
      localStorage.setItem("telefone", newTelefone);
    }
  }
}
</script>

<style scoped>

  .icon-message {
    width: 50px; 
    height: 80px;
    color: var(--background-second);
  }

</style>