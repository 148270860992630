<template>
  <div class="row">
    <div class="col-md-12">
      <form>
        <div class="form-group">
          <b-form-checkbox
            v-model="pesado"
            switch
            class="mr-n2">
            <span>Carreta?</span>
          </b-form-checkbox>
        </div>
        <Marca/>
        <Modelo/>
        <div class="form-row">
          <Ano/>
          <Estado/>
        </div>
        <div class="form-group mt-3">
          <b-form-checkbox
            v-model="implementoFixo"
            switch
            class="mr-n2">
            <span>Possui implemento?</span>
          </b-form-checkbox>
        </div>
        <div class="form-group" v-if="implementoFixo">
          <div class="v-input">
            <fieldset class="fieldset">
                <legend for="implemento"><span>Tipo de implemento</span></legend>
                <div class="v-select">    
                  <v-select
                    v-model="implemento"
                    id="implemento"
                    :loading="loading"
                    label="nome"
                    placeholder="Selecione um implemento"
                    :reduce="opt => opt.id"
                    :options="implementos">
                    <div slot="no-options">Implemento não encontrado</div>
                  </v-select>                 
                </div>
            </fieldset>
          </div>
        </div>
        <div class="form-group" v-if="implementoFixo">
          <div class="v-input input-field">
            <fieldset class="fieldset" :class="{'error-field': invalid}">
                <legend for="valor_implemento"><span>Valor</span></legend>
                <money 
                v-model="valorImplemento" 
                v-bind="{ precision: 2, masked: false }"
                id="valor_implemento"
                placeholder="10.000,00"
                class="form-control"
                type="text"
                ></money> 
            </fieldset>
          </div>
          <b-form-invalid-feedback :force-show="invalid">
            <div v-for="(erro, index) in erros" :key="`error-ano-${index}`">
              {{ erro }}
            </div>
          </b-form-invalid-feedback>
        </div>
        <BtnCotacao/>
      </form>
    </div>
  </div>
</template>

<script>
import Marca from '@/components/Marca.vue';
import Modelo from '@/components/Modelo.vue';
import Ano from '@/components/Ano.vue';
import Estado from '@/components/Estado.vue';
import BtnCotacao from '@/components/BtnCotacao.vue';

export default {
  name: 'Caminhao',
  components: {
    Marca,
    Modelo,
    Ano,
    Estado,
    BtnCotacao,
  },
  data () {
    return {
      price: 123.45
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading.implementos;
    },
    implementos() {
      return this.$store.state.implementos;
    },
    erros() {
      return this.$store.state.erros.valor_implemento;
    },
    invalid() {
      return this.erros.length > 0;
    },
    pesado: {
      get() {
        return this.$store.state.cotacao.pesado;
      },
      set(value) {
        this.$store.commit('SET_COTACAO_PESADO', value);
      }
    },
    implementoFixo: {
      get() {
        return this.$store.state.simulacao.implemento;
      },
      set(value) {
        this.$store.commit('SET_IMPLEMENTO_FIXO', value);
      },
    },
    implemento: {
      get() {
        return this.$store.state.cotacao.implemento_id;
      },
      set(value) {
        this.$store.commit('SET_COTACAO_IMPLEMENTO', value);
      },
    },
    valorImplemento: {
      get() {
        return this.$store.state.cotacao.valor_implemento;
      },
      set(value) {
        this.$store.commit('SET_COTACAO_VALOR_IMPLEMENTO', value);
      },
    },
  },
  mounted(){
    this.$store.dispatch('loadImplementos');
    if(localStorage.getItem("pesadoCaminhao")){
      this.$store.dispatch('setPesadoCaminhao', JSON.parse(localStorage.getItem("pesadoCaminhao")));
    } 
    if(localStorage.getItem("implementoFixo")){
      this.$store.dispatch('setImplemento', localStorage.getItem("implementoFixo"));
    }
    if(localStorage.getItem("implemento")){
      this.$store.dispatch('setImplementoId', Number(localStorage.getItem("implemento")));
    }
    if(localStorage.getItem("valorImplemento")){
      this.$store.dispatch('setValorImplemento', localStorage.getItem("valorImplemento"));
    }
  },
  watch:{
    pesado(newPesado){
      if(newPesado) {
        localStorage.setItem("pesadoCaminhao", newPesado);
      } else {
        localStorage.removeItem("pesadoCaminhao");
      }
    },
    implementoFixo(newImplementoFixo){
      if(newImplementoFixo) {
        localStorage.setItem("implementoFixo", newImplementoFixo);
      } else {
        localStorage.removeItem("implementoFixo");
      }
    },
    implemento(newImplemento){
      if(newImplemento) {
        localStorage.setItem("implemento", newImplemento);
      } else {
        localStorage.removeItem("implemento");
      }
    },
    valorImplemento(newValorImplemento){
      if(newValorImplemento) {
        localStorage.setItem("valorImplemento", newValorImplemento);
      } else {
        localStorage.removeItem("valorImplemento");
      }
    }
  }
}
</script>
