<template>
  <div class="col-md-6 mb-xs-4">
    <div class="v-input" :class="{'v-input-disabled': anos.length === 0}">
      <fieldset class="fieldset" :class="{'error-field': invalid}">
          <legend for="ano"><span>Ano</span></legend>
          <div class="v-select">           
              <v-select
                v-model="ano"
                id="ano"
                :loading="loading"
                label="name"
                :clearable="true"
                :disabled="anos.length === 0"
                placeholder="Selecione um ano"
                :reduce="opt => opt.id"
                :options="anos">
                <div slot="no-options">Ano não encontrado</div>
              </v-select>
          </div>
      </fieldset>
    </div>
    <b-form-invalid-feedback :force-show="invalid">
      <div v-for="(erro, index) in erros" :key="`error-ano-${index}`">
        {{ erro }}
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>

export default {
  name: "Ano",
  computed: {
    loading() {
      return this.$store.state.loading.anos;
    },
    erros() {
      return this.$store.state.erros.ano_id;
    },
    invalid() {
      return this.erros.length > 0;
    },
    anos() {
      return this.$store.state.anos;
    },
    ano: {
      get() {
        return this.$store.state.cotacao.ano_id;
      },
      set(value) {
        return this.$store.dispatch('setAno', value);
      }
    }
  },
  mounted(){
    if(localStorage.getItem("ano")){
      this.$store.dispatch('setAno', localStorage.getItem("ano"));
    }
  },
  watch:{
    ano(newAno){
      if(newAno) {
        localStorage.setItem("ano", newAno);
      } else {
        localStorage.removeItem("ano");
      }
    }
  }
}
</script>
