<template>
  <main>
    <div class="container-md">
      <div class="row">
        <div class="col-md-3 col-md-offset-3"></div>
        <div class="col-md-6">
          <form>
            <h4 class="mt-4 mb-4">Informe os dados para cotação</h4>
            <div class="form-group">
              <b-form-radio-group
                v-model="tipo"
                id="radio-group-2"
                name="tipo"
              >
                <b-form-radio v-for="tipo in tipos" :value="tipo.value" :key="`tipo-${tipo.value}`">
                  {{ tipo.label }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <Carro v-if="tipo === 1"/>
            <Moto v-else-if="tipo === 2"/>
            <Caminhao v-else-if="tipo === 3"/>
          </form>
        </div>
        <div class="col-md-3 col-md-offset-3"></div>
      </div>
    </div>
  </main>
</template>

<script>
import Carro from './Carro.vue';
import Caminhao from './Caminhao.vue';
import Moto from './Moto.vue';

export default {
  name: 'Veiculos',
  components: {
    Carro,
    Caminhao,
    Moto
  },
  data() {
    return {
      tipos: [
        {value: 1, label: 'Carro'},
        {value: 2, label: 'Moto'},
        {value: 3, label: 'Caminhão'},
      ]
    }
  },
  computed: {
    tipo: {
      get() {
        return this.$store.state.cotacao.tipo;
      },
      set(value) {
        this.$store.dispatch('setTipo', {'flag': true, 'label': value } );
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('loadEstados');
    if(localStorage.getItem("tipo")){
      this.$store.dispatch('setTipo', {'flag': false, 'label': Number(localStorage.getItem("tipo")) });
    } else {
      localStorage.setItem("tipo", '1');
      this.$store.dispatch('setTipo', {'flag': true, 'label': 1 } );
    }
  },
  watch:{
    tipo(newTipo){
      localStorage.setItem("tipo", newTipo);
    }
  }
}
</script>

<style scoped>

  h4 {
    margin-top: 2rem;
  }

  .icon-message {
    width: 50px; 
    height: 80px;
    color: var(--background-second);
  }

</style>
