<template>
    <div class="row">
         <div class="col-md-12">
            <form>
                <Marca />
                <Modelo />
                <div class="form-row">
                    <Ano/>
                    <Estado/>
                </div>
                <BtnCotacao/>
            </form>
         </div>
    </div>
</template>

<script>
import Marca from '../components/Marca.vue';
import Modelo from '../components/Modelo.vue';
import Ano from '../components/Ano.vue';
import Estado from '../components/Estado.vue';
import BtnCotacao from '../components/BtnCotacao.vue';

export default {
    name: 'Moto',
    components:{
       Marca,
       Modelo,
       Ano,
       Estado,
       BtnCotacao
    },
}
</script>
