import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Veiculo from '@/pages/Veiculos';
import Cliente from '@/pages/Cliente';
import Cotacao from '@/pages/Cotacao';

const routes = [
    {
        path: '/',
        component: Veiculo
    },
    {
        path: '/cliente',
        name: 'cliente',
        component: Cliente
    },
    {
        path: '/cotacao',
        name: 'cotacao',
        component: Cotacao
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;
