import api from '@/services/api.js';
import fipe from '@/services/fipe.js';
import cotacao from '@/services/cotacao.js';
import cliente from '@/services/cliente.js';

export default {
  async setTipo({commit}, tipo) {

    commit('SET_COTACAO_TIPO', tipo);
    commit('SET_LOADING_MARCAS', true);
    commit('SET_MARCAS', []);

    try {
      const response = await fipe.getMarcas(tipo.label);
      commit('SET_MARCAS', response.data.data);
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_MARCAS', false);
    }
  },
  async setMarca({state, commit}, marca) {
    commit('SET_COTACAO_MARCA', marca);
    commit('SET_LOADING_MODELOS', true);
    commit('SET_MODELOS', []);
    commit('SET_ANOS', []);

    const tipo = state.cotacao.tipo;
    try {
      if(marca) {
        const response = await fipe.getModelos(tipo, marca);
        commit('SET_MODELOS', response.data.data);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_MODELOS', false);
    }
  },
  async setModelo({state, commit}, modelo) {
    commit('SET_COTACAO_MODELO', modelo);
    commit('SET_LOADING_ANOS', true);
    commit('SET_ANOS', []);

    const tipo = state.cotacao.tipo;
    const marca = state.cotacao.marca_id;

    try {
      if(marca && modelo) {
        const response = await fipe.getAnos(tipo, marca, modelo);
        commit('SET_ANOS', response.data.data);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_ANOS', false);
    }
  },
  async setAno({state, commit}, ano) {
    commit('SET_COTACAO_ANO', ano);
    commit('SET_LOADING_FIPE', true);
    commit('SET_FIPE', {});

    const tipo = state.cotacao.tipo;
    const marca = state.cotacao.marca_id;
    const modelo = state.cotacao.modelo_id;

    try {
      if(marca && modelo && ano) {
        const response = await fipe.getVeiculo(tipo, marca, modelo, ano);
        const data = response.data.data;
        commit('SET_FIPE', data);
        commit('SET_COTACAO_FIPE', data.fipe);
        commit('SET_COTACAO_VALOR_FIPE', data.valor);
      }
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_FIPE', false);
    }
  },
  async saveCotacao({state, commit}) {
    commit('SET_LOADING_PAGE', true);
    try {
      const response = await cotacao.store(state.cotacao);
      commit('SET_COTACAO', response.data.data);

    } catch (error) {
      console.log(error.response.data.errors);
      commit('SET_ERROS', error.response.data.errors);
      if(!error.response.data.errors.error_blacklist[0]) {
        commit('SET_BLACKLIST', true);
      }
      throw error;
    } finally {
      commit('SET_LOADING_PAGE', false);
    }
  },
  async saveCliente({state, commit}) {
    commit('SET_LOADING_PAGE', true);

    try {
      const cotacaoId = state.cotacao.id;
      const clienteId = state.cotacao.cliente_id;

      if (clienteId) {
        await cliente.update(cotacaoId, clienteId, state.cotacao);
      } else {
        const response = await cliente.store(cotacaoId, state.cotacao);
        commit('SET_COTACAO_CLIENTE_ID', response.data.data.id);
      }

    } catch (error) {
      console.log(error);
      commit('SET_ERROS', error.response.data.errors);

      throw error;
    } finally {
      commit('SET_LOADING_PAGE', false);
    }
  },
  async loadEstados({commit}) {
    commit('SET_LOADING_ESTADOS', true);
    commit('SET_ESTADOS', []);

    try {
      const response = await api.get('/estados');
      commit('SET_ESTADOS', response.data);
    } catch (error) {
      commit('SET_ESTADOS', []);
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_ESTADOS', false);
    }
  },
  async loadImplementos({commit}) {
    commit('SET_LOADING_IMPLEMENTOS', true);

    try {
      const response = await api.get('/implementos');
      commit('SET_IMPLEMENTOS', response.data);
    } catch (error) {
      console.log(error.response);
      throw error;
    } finally {
      commit('SET_LOADING_IMPLEMENTOS', false);
    }
  },
  async setErrors({commit}, msg) {
    commit('SET_ERROS', msg);
  },
  async setEstado({commit}, value) {
    commit('SET_COTACAO_ESTADO', value);
  },
  async setNome({commit}, value) {
    commit('SET_COTACAO_NOME', value);
  },
  async setEmail({commit}, value) {
    commit('SET_COTACAO_EMAIL', value);
  },
  async setTelefone({commit}, value) {
    commit('SET_COTACAO_TELEFONE', value);
  },
  async setPesadoCaminhao({commit}, value) {
    commit('SET_COTACAO_PESADO', value);
  },
  async setImplemento({commit}, value) {
    commit('SET_IMPLEMENTO_FIXO', value);
  },
  async setImplementoId({commit}, value) {
    commit('SET_COTACAO_IMPLEMENTO', value);
  },
  async setValorImplemento({commit}, value) {
    commit('SET_COTACAO_VALOR_IMPLEMENTO', value);
  }
};
