<template>
  <div class="col-md-6">
    <div class="v-input" :class="{'v-input-disabled': estados.length === 0}">
      <fieldset class="fieldset" :class="{'error-field': invalid}">
          <legend for="estado"><span>Estado</span></legend>
          <div class="v-select">           
              <v-select
                v-model="estado"
                id="estado"
                :loading="loading"
                label="uf"
                :clearable="true"
                :disabled="estados.length === 0"
                placeholder="Selecione um estado"
                :reduce="opt => opt.id"
                :options="estados">
                <div slot="no-options">Estado não encontrado</div>
              </v-select>
          </div>
      </fieldset>
    </div>
    <b-form-invalid-feedback :force-show="invalid">
      <div v-for="(erro, index) in erros" :key="`error-ano-${index}`">
        {{ erro }}
      </div>
    </b-form-invalid-feedback>
  </div>
</template>

<script>

export default {
  name: "Estado",
  computed: {
    loading() {
      return this.$store.state.loading.estados;
    },
    erros() {
      return this.$store.state.erros.estado_id;
    },
    invalid() {
      return this.erros.length > 0;
    },
    estados() {
      return this.$store.state.estados;
    },
    estado: {
      get() {
        return this.$store.state.cotacao.estado_id;
      },
      set(value) {
        return this.$store.dispatch('setEstado', value);
      }
    }
  },
  mounted(){
    if(localStorage.getItem("estado")){
      this.$store.dispatch('setEstado', Number(localStorage.getItem("estado")));
    }
  },
  watch:{
    estado(newEstado){
      if(newEstado) {
        localStorage.setItem("estado", newEstado);
      } else {
        localStorage.removeItem("estado");
      }
    }
  }
}
</script>
