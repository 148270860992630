<template>
<main>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-cz-8">
          <div class="detail-plan">
            <h4 class="mb-4 text-center">Dados do Veículo</h4>
            <div class="row">
              <div class="col-md-7">
                <div class="text-left mb-4">
                  <b>Marca: </b>{{ fipe.marca }}
                  <br>
                  <b>Modelo: </b>{{ fipe.modelo }}
                  <br>
                  <b>Ano: </b>{{ fipe.ano }}
                </div>
              </div>
              <div class="col-md-5">
                <div class="box-fipe text-center">
                  <b>Valor FIPE</b>
                  <div>{{ Number(fipe.valor).toLocaleString('pt-BR', this.format) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="detail-plan">
            <h4 class="mb-4 text-center">Serviços Opcionais</h4>
            <div class="opcionais" v-if="opcionaisAssistencias.length > 0">
              <label class="text-left txt-label">Assistência 24 horas</label>
              <b-form-radio-group 
                class="text-left"
                v-model="assistenciaSelecionado"
                :options="opcionaisAssistencias"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
            <div class="opcionais" v-if="opcionaisPar.length > 0">
              <label class="text-left txt-label">PAR - Danos a terceiros</label>
              <b-form-radio-group class="text-left"
                v-model="parSelecionado"
                :options="opcionaisPar"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
            <div class="opcionais" v-if="opcionaisVidros.length > 0">
              <label class="text-left txt-label">Desc. 70% - Vidros, Farois, Lanternas e Retrovisores</label>
              <b-form-radio-group class="text-left"
                v-model="vidroSelecionado"
                :options="opcionaisVidros"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
            <div class="opcionais" v-if="cotacao.tipo === 1 && opcionaisCarro.length > 0">
              <label class="text-left txt-label">Carro Reserva</label>
              <b-form-radio-group class="text-left"
                v-model="carroSelecionado"
                :options="opcionaisCarro"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
            <div class="opcionais" v-if="cotacao.tipo === 3 && opcionaisMorte.length > 0">
              <label class="text-left txt-label">M.A.O</label>
              <b-form-radio-group class="text-left"
                v-model="morteSelecionado"
                :options="opcionaisMorte"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
            <div class="opcionais" v-if="cotacao.tipo === 2 && opcionaisRastreador.length > 0">
              <label class="text-left txt-label">Rastreador</label>
              <b-form-radio-group class="text-left"
                v-model="rastreadorSelecionado"
                :options="opcionaisRastreador"
                text-field="descricao"
                value-field="valor"
                @change="soma()"
                stacked>
              </b-form-radio-group>
            </div>
          </div>
          <div class="detail-plan">
            <h4 class="mb-4 text-center">Detalhes do Plano</h4>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/roubo.png" alt="Roubo ou Furto"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Roubo ou Furto</b>
                      <p v-if="cotacao.plano_id !== 4">Incendio/Colisao/PT*</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse1 = !collapse1" v-b-toggle.collapse-1 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon  v-if="!collapse1" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse1" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-1" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          A APVS oferece a cobertura contra roubo ou furto, que visa proteger o patrimônio de todos os nossos associados, 
                          tão duramente conquistado. Se não for localizado você recebe o valor integral do seu veículo!
                        </p>
                        <div class="badge-i">
                          <b-badge variant="primary">INDENIZAÇÃO 100% DA TABELA FIPE</b-badge>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/natureza.png" alt="Fenômenos da Natureza"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Fenômenos da Natureza *</b>
                      <p>Alagamentos, queda de arvores ou chuvas de granizo</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse2 = !collapse2" v-b-toggle.collapse-2 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse2" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse2" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-2" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          Seu veículo sendo danificado por alagamentos, queda de arvores, 
                          ou chuvas de granizo, a APVS oferece a cobertura total garantida.
                        </p>
                        <div class="badge-i">
                          <b-badge variant="primary">INDENIZAÇÃO 100% DA TABELA FIPE</b-badge>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon box-icon-rastreador float-left">
                      <img src="../assets/icon/truck.png" alt="Rastreador"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Rastreador</b>
                      <p>Monitoramento 24 horas</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse3 = !collapse3" v-b-toggle.collapse-3 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse3" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse3" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-3" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          Se seu veículo estiver acima de 95 mil reais, você ganha um rastreador 
                          sem custo mensal para monitorar ele onde estiver, e além disso você terá 
                          acesso via login e senha no aplicativo.
                        </p>
                        <div class="badge-i">
                          <b-badge variant="primary">INDENIZAÇÃO 100% DA TABELA FIPE</b-badge>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>

            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/transport.png" alt="Assistência 24 Horas"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Assistência 24 Horas</b>
                      <p>100km</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse4 = !collapse4" v-b-toggle.collapse-4 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse4" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse4" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-4" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          Em caso de pane elétrica ou mecãnica no seu veículo, 
                          você pode acionar até 1 assistência por mês, e em caso de acidente 0 km é ilimitado.
                        </p>
                        <div class="text-center mt-3">
                          <b-badge pill variant="info mr-2">Registro de CNVR</b-badge>
                          <b-badge pill variant="info mr-2">Retorno ao domicílio</b-badge>
                          <b-badge pill variant="info mr-2">Translado de corpos</b-badge>
                          <b-badge pill variant="info mr-2">Transmissão de Mensagens</b-badge>
                          <b-badge pill variant="info mr-2">Reboque 300km</b-badge>
                          <b-badge pill variant="info mr-2">Socorro Elétrica</b-badge>
                          <b-badge pill variant="info mr-2">Pane Mecânica</b-badge>
                          <b-badge pill variant="info mr-2">Chaveiro</b-badge>
                          <b-badge pill variant="info mr-2">Envio de acompanhante</b-badge>
                          <b-badge pill variant="info mr-2">Remoção inter hospitalar</b-badge>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/wind.png" alt="Assistência Residencial"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Assistência Residencial</b>
                      <p>Chaveiro, eletricista e encanador</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse5 = !collapse5" v-b-toggle.collapse-5 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse5" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse5" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-5" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          A Assistência Residencial é um pacote de serviços, que tem como objetivo atender 
                          alguma eventual emergência em sua residência. Você pode solicitar o serviço de um 
                          especialista para solucionar um problema em sua casa (desde que dentro da cobertura 
                          prevista em seu contrato junto a APVS).
                        </p>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/rip.png" alt="Auxílio Funeral"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Auxílio Funeral</b>
                      <p>Reembolso de ate R$ 2.000,00</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse6 = !collapse6" v-b-toggle.collapse-6 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse6" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse6" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-6" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          No auxílio funeral da APVS, a família tem direito ao reembolso 
                          dos valores pagos com o funeral de ate <b>R$ 2.000,00</b>.
                        </p>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="opcionais">
              <div class="list-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="box-icon float-left">
                      <img src="../assets/icon/pet.png" alt="Auxílio Funeral"> 
                    </div>
                    <div class="float-left t-block">
                      <b>Assistência Pet **</b>
                      <p>Reembolso de ate R$ 250,00</p>
                    </div>
                    <div class="float-right">
                      <b-button @click="collapse7 = !collapse7" v-b-toggle.collapse-7 variant="link" class="mt-4" v-b-tooltip.hover title="Detalhes de cobertura">
                        <b-icon v-if="!collapse7" icon="arrow-down-square"></b-icon>
                        <b-icon v-if="collapse7" icon="arrow-up-circle"></b-icon>
                      </b-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-collapse id="collapse-7" class="mt-4 float-none">
                      <b-card>
                        <p class="card-title">Detalhes da cobertura</p>
                        <p class="card-text">
                          A Assistência Pet da APVS é um combo de serviços veterinários que oferece ampla 
                          rede de atendimento para animais domésticos, seja para procedimento de emergência ou 
                          decorrente de doença.
                        </p>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right text-obs">
                <p>* Veículos pertencentes ao grupo de risco não possuem.</p>
                <p>* Os valores apresentados nessa proposta poderão sofrer alterações.</p>
                <p>** 2 atendimentos por ano.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-cz-4 mt-cz-4">
          <div class="plan-selected" :class="{'fixed' : fixedSidebar}">
            <div class="price_month">
              <p>O valor total da sua proteção é:</p>          
              <strong>{{ mensal.toLocaleString('pt-BR', this.format) }}</strong>
              <p>Mensalidade</p>
            </div>
            <div class="price_accession">
              <p>Valor anual de: </p>     
              <strong>{{ valorTotal.toLocaleString('pt-BR', this.format) }}</strong>
            </div>
            <div class="btn-quote">
              <b-overlay>
                <b-btn
                  class="mt-4 w-100"
                  @click="finish"
                  variant="success"
                  size="lg"
                  block
                >
                  QUERO CONTRATAR
                </b-btn>
              </b-overlay>
            </div>
            <div class="itens-p">
              <div class="item">
                <div class="icon-item">
                  <b-icon icon="circle"></b-icon> 
                </div>
                <div class="item-msg">
                  O valor da mensalidade será cobrada mês a mês por boleto bancario.
                </div>
              </div>
              <div class="item">
                <div class="icon-item">
                  <b-icon icon="circle"></b-icon> 
                </div>
                <div class="item-msg">
                  O valor da sua adesão será  R$ {{ Number(valorAdesao).toLocaleString('pt-BR', this.format) }} (Cobrado uma unica vez).
                </div>
              </div>
              <div class="item">
                <div class="icon-item">
                  <b-icon icon="circle"></b-icon> 
                </div>
                <div class="item-msg">
                  O valor da mensalidade será cobrada mês a mês por boleto bancario.
                </div>
              </div>
              <div class="item">
                <div class="icon-item">
                  <b-icon icon="circle"></b-icon> 
                </div>
                <div class="item-msg">
                  Um consultor entrará em contato para assinatura do termos de adesão e agendamento da vistoria.
                </div>
              </div>
            </div>
            <div class="btn-whatsapp">
                <b-button pill variant="outline-success" class="w-100"> 
                  <span aria-label="Whatsapp icon" role="img" class="material-design-icon whatsapp-icon"><svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" class="material-design-icon__svg"><path d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"><title>Whatsapp icon</title></path></svg></span>
                  FALE NO WHATSAPP
                </b-button>
            </div>
            <div class="disk text-center">
              <h4>0800-000-0000</h4>
              <p>Dúvidas? Ligue para gente!</p>
            </div>
          </div>
          <div class="plan-selected-mobile" :class="{'hide' : footerValue}">
            <div class="icon-mobile text-center">
              <b-button v-b-toggle.collapse-mobile variant="link" size="sm" @click="visible = !visible" >
                <b-icon icon="chevron-double-up" v-if="!visible"></b-icon>
                <b-icon icon="chevron-double-down" v-if="visible"></b-icon>
              </b-button>
            </div>
            <div class="row price-mobile hr">
              <div class="col-6">
                <span>Mensalidade</span>
              </div>
              <div class="col-6 text-right">
                <strong >{{ mensal.toLocaleString('pt-BR', this.format) }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <span>Valor anual</span>
              </div>
              <div class="col-6 text-right">
                <strong>{{ valorTotal.toLocaleString('pt-BR', this.format) }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-overlay>
                  <b-btn
                    class="mt-4 w-100"
                    @click="finish"
                    variant="success"
                    size="sm"
                    block
                  >
                    QUERO CONTRATAR
                  </b-btn>
                </b-overlay>
              </div>
            </div>
            <b-collapse id="collapse-mobile">
              <div class="itens-p">
                <div class="item">
                  <div class="icon-item">
                    <b-icon icon="circle"></b-icon> 
                  </div>
                  <div class="item-msg">
                    O valor da mensalidade será cobrada mês a mês por boleto bancario.
                  </div>
                </div>
                <div class="item">
                  <div class="icon-item">
                    <b-icon icon="circle"></b-icon> 
                  </div>
                  <div class="item-msg">
                    O valor da sua adesão será R$ {{ Number(valorAdesao).toLocaleString('pt-BR', this.format) }} (Cobrado uma unica vez).
                  </div>
                </div>
                <div class="item">
                  <div class="icon-item">
                    <b-icon icon="circle"></b-icon> 
                  </div>
                  <div class="item-msg">
                    O valor da mensalidade será cobrada mês a mês por boleto bancario.
                  </div>
                </div>
                <div class="item">
                  <div class="icon-item">
                    <b-icon icon="circle"></b-icon> 
                  </div>
                  <div class="item-msg">
                    Um consultor entrará em contato para assinatura do termos de adesão e agendamento da vistoria.
                  </div>
                </div>
              </div>
              <div class="btn-whatsapp">
                  <b-button pill variant="success" class="w-100"> 
                    <span aria-label="Whatsapp icon" role="img" class="material-design-icon whatsapp-icon"><svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" class="material-design-icon__svg"><path d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"><title>Whatsapp icon</title></path></svg></span>
                    FALE NO WHATSAPP
                  </b-button>
              </div>
              <div class="disk text-center">
                <h4>0800-000-0000</h4>
                <p>Dúvidas? Ligue para gente!</p>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import api from '@/services/api.js';

export default {
  name: 'Cotacao',
  data() {
    return {
      valorTotal: 0,
      valorAdesao: 0,
      mensal: 0,
      parSelecionado: 0,
      assistenciaSelecionado: 0,
      vidroSelecionado: 0,
      carroSelecionado: 0,
      rastreadorSelecionado: 0,
      morteSelecionado: 0,
      format: {minimumFractionDigits: 2, style: 'currency', currency: 'BRL'},
      opcionaisSelecionados: [],
      opcionais: [],
      opcionaisPar: [],
      opcionaisAssistencias: [],
      opcionaisVidros: [],
      opcionaisCarro: [],
      opcionaisMorte: [],
      opcionaisRastreador: [],
      fixedSidebar: false,
      visible: false,
      footerValue: false,
      setCollapse: [ false, "collapse-mobile" ],
      collapse1: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      collapse5: false,
      collapse6: false,
      collapse7: false
    }
  },
  computed: {
    cotacao() {
      return this.$store.state.cotacao;
    },
    fipe() {
      return this.$store.state.fipe;
    }
  },
  methods: {
    soma: function () {
      this.valorTotal = this.cotacao.valor_plano * 12;
      this.mensal = this.valorTotal / 12;
      this.mensal += this.assistenciaSelecionado;
      this.mensal += this.parSelecionado;
      this.mensal += this.carroSelecionado;
      this.mensal += this.vidroSelecionado;
      this.mensal += this.rastreadorSelecionado;
      this.valorTotal = this.mensal * 12;
    },
    finish() {
      localStorage.clear();
    },
    handleScroll : function(){
      if (window.scrollY > 267) {
        this.fixedSidebar = true;
      } else {
        this.fixedSidebar = false;
      }
      if(window.scrollY > 2100) {
        this.footerValue = true;
      } else {
        this.footerValue = false;
      }
    }
  },
  mounted() {

    const tipo = this.cotacao.tipo;
    const valorFipe = this.cotacao.valor_fipe;
    const planoEstadoId = this.cotacao.plano_estado_id;
    api.get(`/planoEstado/${planoEstadoId}`).then((response) => {
      const data = response.data.data;

      const opcionais = data.opcionais;
      if (tipo === 1 && valorFipe <= 121000.01) {
        this.parSelecionado = 18.75;
      } 
      /*else if (tipo === 2) {
        this.assistenciaSelecionado = 7.37;
      }*/

      opcionais.forEach(opcional => {
        if(opcional.opcional_tipo_id === 1) {
          if (tipo === 1 && opcional.valor === 18.75 && valorFipe > 121000.01) {
            opcional.disabled = true;
          }
          this.opcionaisPar.push(opcional);
        } else if(opcional.opcional_tipo_id === 2) {
          this.opcionaisAssistencias.push(opcional);
        } else if(opcional.opcional_tipo_id === 3) {
          this.opcionaisVidros.push(opcional);
        } else if(opcional.opcional_tipo_id === 4) {
          this.opcionaisCarro.push(opcional);
        } else if(opcional.opcional_tipo_id === 5) {
          this.opcionaisMorte.push(opcional);
        } else if(opcional.opcional_tipo_id === 6) {
          this.opcionaisRastreador.push(opcional);
        }
      });

      this.valorTotal = this.cotacao.valor_plano * 12;
      this.mensal = this.valorTotal / 12;
      this.valorAdesao = this.cotacao.valor_adesao;

    }).catch((error) => {
      console.log(error.response)
    });

  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }

}

</script>

<style scoped>

.divinfo {
  height: 100vh;
  top: 0px;
  max-height: calc(100% + 0px);
  transform: translateX(0%);
  width: 380px;
  background: #13547a !important;
  color: lemonchiffon;
}

.divtext {
  font-size: 10px;
}

.titulo {
  text-align: center;
  padding: 0;
  font-weight: 500;
  font-size: 1.25rem !important;
  letter-spacing: .0125rem !important;
}

.opcionais {
  border-radius: 4px;
  background-color: #FBFCFD;
  color: #000;
  border: 1px solid #d8e2ef;
  width: 100%;
  padding: 1rem;
  margin-bottom: 15px;
}

.detail-plan {
  background: #FFF;
  border-radius: 4px;
  padding: 30px 20px 5px 20px !important;
  border: 1px solid #d8e2ef;
  margin-top: 30px;
}

.txt-label {
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.plan-selected,
.plan-selected-mobile {
  background: #FFF;
  border-radius: 4px;
  padding: 30px 20px;
  border: 1px solid #d8e2ef;
  position: relative;
  top: 30px;
}

.plan-selected-mobile {
  top: initial;
  border: none;
  border-radius: 0;
  background: var(--background-primary);
  color: #FFF;
  border-top: 5px solid var(--background-second);
}

.plan-selected-mobile .itens-p .icon-item {
  color: var(--background-second);
}

.plan-selected .price_month,
.plan-selected-mobile .price_month {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #a9b1ba;
    text-align: center;
}

.plan-selected p,
.plan-selected-mobile p  {
  margin-bottom: 0;
}

.plan-selected .price_month strong,
.plan-selected-mobile .price_month strong {
    font-size: 2.8rem;
    line-height: 3.2rem;
}

.fixed {
  position: sticky;
  right: 0;
  z-index: 10;
  transition: top 0.5s;
  top: 30px;
}

.price_accession {
  text-align: center;
}

.price_accession strong{
  font-size: 1.2rem;
}

.box-fipe {
  background: #d8e2ef;
  padding: 10px 0;
}

.detail-plan label {
  font-weight: 600;
  margin-bottom: 10px;
}

.itens-p {
  margin-bottom: 30px;
}

.itens-p .item {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px dotted #a9b1ba;
  font-size: 12px;
  overflow: hidden;
}

.itens-p .icon-item {
    width: 10%;
    float: left;
    text-align: center;
    margin-top: 10px;
    color: #28a745;
}

.itens-p .item-msg {
    width: 90%;
    float: left;
}

.itens-p .item:first-child {
  border-top: 1px dotted #a9b1ba;
  padding-top: 5px;
}

.btn-quote {
  margin-bottom: 30px;
}

.btn-whatsapp {
  margin-bottom: 30px;
}

.btn-whatsapp .whatsapp-icon {
  vertical-align: 2px;
}

.disk h4 {
  line-height: 12px;
}

.list-detail {
  margin-top: 0;
}


.list-detail .row {
  border-bottom: 1px solid #d8e2ef;
  padding: 0;
  margin-right: 0px;
  margin-left: 0;
}

.list-detail .row>div{
  padding-right: 0px;
  padding-left: 0px;
}

.list-detail .icon {
  color: #28a745;
  font-size: 1.8rem;
  vertical-align: middle;
}

.list-detail .row:last-child  {
  border-bottom: none;
}

.text-obs {
  margin-top: 10px;
}

.text-obs p {
  line-height: 10px;
  font-size: .8rem;
}

.plan-selected {
  display: block;
}

.plan-selected-mobile {
  display: none;
}

.icon-mobile {
  margin-top: -10px;
}

.icon-mobile .b-icon {
  color: var(--background-light);
}

.icon-mobile button {
  margin-top: -20px;
}

#collapse-mobile {
    margin-top: 30px;
}

.hr {
  border-bottom: 1px dotted #a9b1ba;
  margin: 0 -15px 10px;
  padding: 0 0 10px 0;
}

.card-title {
  font-weight: bold;
}

.card-text {
  margin-bottom: 0;
}

.box-icon {
  width: 80px;
  height: 80px;
  max-width: 80px;
  border-radius: 50%;
  overflow: hidden;
  padding: 7px;
  background: #FFF;
  box-shadow: 1px 1px 16px #d8d8d8;
}

.box-icon-rastreador {
  padding: 12px;
}

.box-icon img {
  width: 65px;
}

.box-icon-rastreador  img {
  width: 55px;
}

.t-block {   
   margin: 15px 0 0 20px;
}

.card {
  border: 1px solid #d8e2ef !important;
}

.badge-i {
  text-align: center;
  margin-top: 10px;
}

.badge-i .badge {
  padding: 10px;
  font-size: 14px;
}

@media (max-width: 576px) {

  .box-fipe {
    margin-bottom: 15px;
  }

  .list-detail .row>div {
    float: none !important;
  }

  .plan-selected-mobile {
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 0;
    left: 0;
  }

  .plan-selected {
    display: none;
  }

  .plan-selected-mobile {
    display: block;
  }

  .btn-quote {
    margin-bottom: 0;
  }

  .detail-plan {
    margin-top: 15px;
    padding: 20px 15px 0px 15px !important;
  }

  .opcionais {
    margin-bottom: 15px;
  }

  .text-obs p {
    line-height: 12px;
    font-size: .6rem;
  }

  .box-icon {
    float: none !important;
    width: 100%;
    margin: 15px auto 0 auto;
  }

  .t-block{
    float: none !important;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .list-detail .float-right {
    float: none !important;
    text-align: center;
  }

  .list-detail .float-right button {
    margin-top: 0 !important;
  }

  .badge-i .badge {
    font-size: 10px;
  }

}

@media (max-width: 1200px) {
  .col-cz-8,
  .col-cz-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mt-cz-4 {
    margin-top: 30px;
  }
}

</style>
